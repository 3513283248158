<template>
  <v-text-field
    v-bind="$attrs"
    v-model="value"
    density="compact"
    rounded="lg"
    variant="outlined"
    hide-details="auto"
    color="var(--theme-icone)"
    class="text-input my-2"
    :error-messages="errorMessage"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  name: String,
});

const {value, errorMessage} = useField(() => props.name);
</script>

<style lang="scss">
.text-input {
  .v-text-field__slot,
  .v-label {
    height: auto !important;
    font-weight: 700;
    font-size: 14px;
  }

  .v-field__input {
    font-size: 14px;
  }
}
</style>
